export default class ArticlesSearchForm {
    constructor() {
        this.body = document.querySelector('body');
        this.block = document.querySelector('.articles-search');
        if (this.block) {
            this.loupe = this.block.querySelector('.loupe');
            this.categorySelector = this.block.querySelector('.js-category-select');
            this.bindEvents();
        }
    }

    bindEvents = () => {
        this.loupe.addEventListener('click', (event) => {
            event.preventDefault();
            document.querySelector('.js-search-form').submit();
        });

        this.body.addEventListener('keyup', (event) => {
            if (event.code === 'Enter') {
                event.preventDefault();
                document.querySelector('.js-search-form').submit();
            }
        });

        this.categorySelector.addEventListener('change', (event) => {
            if (!event.target.value == '') {
                window.location = event.target.value;
            } else {
                window.location = this.block.querySelector('#pagePath').value;
            }
        });

        let categories = this.categorySelector.querySelectorAll('option');
        categories.forEach((category) => {
            if (!category.value == '' && window.location.href.includes(category.value)) {
                this.categorySelector.value = category.value;
            }
        });
    };
}