import Header from './js/elements/header';
import Autonav from './js/blocks/autonav';
import OurPeopleForm from './js/blocks/express_entry_list/templates/our-people';
import ArticlesSearchForm from "./js/blocks/search/templates/articles-search";
import ExpressForm from "./js/blocks/express_form/view";

export default class App {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
            this.initialize();
        });
    }

    initialize = () => {
        this.header = new Header();
        this.autonav = new Autonav();
        new ExpressForm();
        new OurPeopleForm();
        new ArticlesSearchForm();
    };
}

window.App = new App();