export default class ExpressForm {
    constructor() {
        this.forms = document.querySelectorAll('.express-form');
        if (this.forms.length > 0) {
            this.initialize();
        }
    }

    initialize = () => {
        this.forms.forEach(function (form) {
            let email = form.querySelector('input[type="email"]');
            let phone = form.querySelector('input[type="tel"]');
            let message = form.querySelector('textarea');
            email.placeholder = 'Email';
            phone.placeholder = 'Phone';
            message.placeholder = 'Message';
        });
    };
}