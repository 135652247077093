export default class Header {
    constructor() {
        this.body = document.querySelector('body');
        this.menu = document.querySelector('.js-header-menu');
        this.menuToggle = document.querySelector('.js-header-menu-toggle');
        this.nav = this.menu.querySelector('.autonav');
        this.bindEvents();
    }

    bindEvents = () => {
        window.addEventListener('load', this.calculateMenuHeight());
        window.addEventListener('resize', this.calculateMenuHeight());
        window.addEventListener('orientationchange', this.calculateMenuHeight());
        this.menuToggle.addEventListener('click', () => {
            this.body.classList.toggle('no-overflow');
            this.menuToggle.classList.toggle('active');
            this.menu.classList.toggle('js-collapsed');

            if (this.menu.classList.contains('js-collapsed')) {
                this.nav.classList.add('autonav_collapsed');
            } else {
                this.nav.classList.remove('autonav_collapsed');
            }
        });
    };

    calculateMenuHeight = () => {
        //this.menu.style.maxHeight = `${window.innerHeight - 110}px`;
    };

    getElementClassName = (element) => {
        let className = element.className;
        return className.split(' ')[0];
    };
}
