export default class OurPeopleForm {
    constructor() {
        this.body = document.querySelector('body');
        this.block = document.querySelector('.our-people');
        if (this.block) {
            this.loupe = this.block.querySelector('.loupe');
            this.selects = this.block.querySelectorAll('select');
            this.bindEvents();
        }
    }

    bindEvents = () => {
        this.loupe.addEventListener('click', (event) => {
            event.preventDefault();
            document.querySelector('.js-search-form').submit();
        });

        this.selects.forEach((select) => {
            select.addEventListener('change', () => {
                document.querySelector('.js-search-form').submit();
            });
        });

        this.body.addEventListener('keyup', (event) => {
            if (event.code === 'Enter')
            {
                event.preventDefault();
                document.querySelector('.js-search-form').submit();
            }
        });
    };
}
