export default class Autonav {
    constructor() {
        this.desktopVersionInitialized = false;
        this.mobileVersionInitialized = false;
        this.dropdownToggles = document.querySelectorAll("li[class*='has-dropdown']");
        this.bindEvents();
        this.initializeNav();
    }

    bindEvents = () => {
        window.addEventListener('load', this.initializeNav());
        window.addEventListener('resize', this.initializeNav());
        window.addEventListener('orientationchange', this.initializeNav());
    };

    initializeNav = () => {
        if (window.innerWidth >= 992) {
            if (!this.desktopVersionInitialized) {
                this.dropdownToggles.forEach((toggle) => {
                    let dropdown = toggle.querySelector("ul[class*='dropdown']");
                    let dropdownClassName = this.getElementClassName(dropdown);
                    if (dropdown.classList.contains('dropdown-additional')) {
                        dropdown.style.paddingLeft = `${toggle.offsetLeft - 210}px`;
                    } else {
                        dropdown.style.paddingLeft = `${toggle.offsetLeft}px`;
                    }
                    let dropdownModifierName = `${dropdownClassName}_visible`;
                    toggle.addEventListener('mouseenter', () => {
                        dropdown.classList.add(dropdownModifierName);
                    });
                    toggle.addEventListener('mouseleave', () => {
                        dropdown.classList.remove(dropdownModifierName);
                    });
                });
                this.desktopVersionInitialized = true;
                //console.log('desktop');
            }
        } else if (!this.mobileVersionInitialized) {
            this.dropdownToggles.forEach((toggle) => {
                let dropdown = toggle.querySelector("ul[class*='dropdown']");
                let dropdownClassName = this.getElementClassName(dropdown);
                toggle.addEventListener('click', (e) => {

                    if (!e.target.classList.contains('autonav__dropdown-link') && !e.target.classList.contains('js-search-submit') && !e.target.classList.contains('opened')) {
                        e.preventDefault();
                        e.stopPropagation();
                        dropdown.classList.toggle(`${dropdownClassName}_opened`);
                        e.target.classList.toggle('opened');
                    }
                });
            });
            this.mobileVersionInitialized = true;
            //console.log('mobile');
        }
    };
    getElementClassName = (element) => {
        let className = element.className;
        return className.split(' ')[0];
    };
}
